import React, { useState } from 'react';
import AddProject from './AddProject';

function App() {
  const [singleRole, setSingleRole] = useState(false)
  return (
    <div className="App">
      <header className="App-header">
        <span
         onClick={() => setSingleRole(!singleRole)}
         style={{
          color: '#fff',
          position: 'fixed',
          right: '2%',
          top: '1.5%',
          cursor: 'pointer'
        }}>switch view</span>
      </header>
      <div className="sidebar">

      </div>
      <AddProject singleRole={singleRole}/>
    </div>
  );
}

export default App;
