import React from 'react'   
import Proptypes from 'prop-types'
import Select from 'react-select'

const options = [{
    value: 1,
    label: 'nikhil.anantharaman@betsol.com'
}, {
    value: 2,
    label: 'adithya.ramamurthy@betsol.com'
}, {
    value: 3,
    label: 'leto.admin@betsol.com'
}]

const roles = [{
    value: 2,
    label: 'Project Lead'
}, {
    value: 3,
    label: 'Project Users'
}]

export const AddProject = ({singleRole}) => {
    const inputRef = React.useRef(null)
    const [users] = React.useState([])

    const addUser = () => {
        let selectedUsers = inputRef.current.state.value
        selectedUsers.forEach(user => users.push({
            id: user.value,
            username: user.label
        }))
        console.log(users)
        // await setUsers(users)
    }
    return (
        <div className="add-user">
            <h2 style={{color: '#003b71'}}>Add Users to Project</h2>
            <label htmlFor="select-users">Select Project Users</label>
            <Select
             ref={inputRef}
             id="select-users"
             className="select"
             options={options}
             placeholder="Type here to select..."
             isMulti
             /> <br/>
             <label htmlFor="select-admins">Select Project {singleRole ? 'Role' : 'Leads'}</label>
             <Select
             id="select-admins"
             className="select"
             options={singleRole ? roles : options}
             placeholder="Type here to select..."
             isMulti={!singleRole}
             />
             <div className="button-group">
                <button className="cancel">
                    Cancel
                </button>
                <button onClick={() => addUser()} className="add">
                    Add
                </button>                 
             </div>
        </div>
    )
}

AddProject.propTypes = {
    singleRole: Proptypes.bool.isRequired
}

export default AddProject